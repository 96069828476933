import React, { useEffect } from "react";
// import Header from '../components/Header';
import Navbar from "../components/Navbar";
import Hero from "../components/Hero";
import { graphql } from "gatsby";
import "../styles/styles.scss";
import { addEventListner, onClientEntry } from "../utils/Event";
import { ThemeProvider } from "@emotion/react";
import theme from "../components/Theme";
import Use from "../components/Use";
import ThirdCard from "../components/ThirdCard";
import FifthCards from "../components/FifthCards";
import SixthCard from "../components/SixthCard";
import FourthCard from "../components/FourthCard";
import heroData from "../data/hero_data";
import third_card from "../data/third_data";
import sheduleData from "../data/shedule_data";
import HeroImage from "../images/hero-image3.png";
// import HeroImage from '../images/office-web.jpg'

import TaskAltOutlinedIcon from "@mui/icons-material/TaskAltOutlined";
import TouchAppOutlinedIcon from "@mui/icons-material/TouchAppOutlined";
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";
import icon from "@mui/material/SvgIcon";
import MediaComp from "../components/MediaComp.jsx";
import Toast from "../components/Toast";
import Footer from "../components/Footer";
import mediaData from '../data/video_data'
import Footer2 from '../components/Footer2'
const OfficeManagement = () => {
  // useEffect(() => addEventListner());
  // useEffect(() => onClientEntry());

  heroData.heroData3.image = HeroImage;

  third_card.feature_page_3.list.map((ele) => {
    switch (ele.name) {
      case "Accurate Data":
        ele.iconStyle.icon = (
          <TaskAltOutlinedIcon
            sx={{
              fontSize: ele.iconStyle.iconSize,
              color: `${ele.iconStyle.iconColor}`,
            }}
          />
        );

        break;
      case "Streamline Workflow":
        ele.iconStyle.icon = (
          <TouchAppOutlinedIcon
            sx={{
              fontSize: ele.iconStyle.iconSize,
              color: `${ele.iconStyle.iconColor}`,
            }}
          />
        );
        break;
      case "Mitigate Risks":
        ele.iconStyle.icon = (
          <NotificationsActiveOutlinedIcon
            sx={{
              fontSize: ele.iconStyle.iconSize,
              color: `${ele.iconStyle.iconColor}`,
            }}
          />
        );

        break;
      default:
        ele.iconStyle.icon = {};
    }
  });

  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <Toast/>
        <Navbar />
        <Hero heroData={heroData.heroData3} />
       <ThirdCard thirdCardData={third_card.feature_page_3} />
        <MediaComp  data={mediaData.officeManage}/>
        <FifthCards sheduleData={sheduleData.banner3} />
        <SixthCard /> 
        <Footer/>
        <Footer2/>
      </ThemeProvider>
    </React.Fragment>
  );
};

export default OfficeManagement;
